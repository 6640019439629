<template>
    <div :class="CSSClasses" class="gallery-block">
        <Carousel v-if="images.length > 1" :items-to-show="1" wrapAround ref="GalleryCarousel" :dir="direction">
            <Slide v-for="image in images" :key="image.url">
                <figure class="gallery-figure">
                    <Image v-bind="image" class="image" />
                    <figcaption v-if="isDekNotEmpty(image.caption)" class="caption">
                        <RichTextElements :elements="image.caption" bodyStyle="caption" />
                    </figcaption>
                </figure>
            </Slide>

            <template #addons>
                <Pagination class="gallery-pagination" />
            </template>
        </Carousel>
        <Image v-if="images.length == 1" v-bind="images[0]" class="image" />
        <div class="gallery-lower">
            <div v-if="images.length > 1" class="gallery-navigation">
                <button
                    class="gallery-nav-button gallery-nav-button-prev"
                    aria-label="Navigate to previous slide"
                    @click="handlePrevClicked"
                >
                    <Icon class="arrow-icon" name="arrow-left" />
                </button>
                <button
                    class="gallery-nav-button gallery-nav-button-next"
                    aria-label="Navigate to next slide"
                    @click="handleNextClicked"
                >
                    <Icon class="arrow-icon" name="arrow-right" />
                </button>
            </div>
            <div class="gallery-info">
                <Typography class="title" v-if="header?.headerTitle" :as="header?.type" :variant="header?.type">
                    {{ header?.headerTitle }}
                </Typography>
                <RichTextElements v-if="isDekNotEmpty(subheader)" :elements="subheader" />
                <div v-if="buttons" class="gallery-buttons">
                    <template v-for="button in buttons" :key="button.button?.label">
                        <CarnegieButton
                            class="car-button"
                            v-bind="{ ...button.button, ...(size === 'full' ? sizeFullButtonProps : addedButtonProps) }"
                        />
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination } from 'vue3-carousel';

const GalleryCarousel = ref(null);

const handleNextClicked = () => {
    GalleryCarousel.value.next();
};
const handlePrevClicked = () => {
    GalleryCarousel.value.prev();
};

const props = defineProps({
    size: {
        ...sizes,
    },
    header: {
        type: Object,
    },
    subheader: {
        type: Object,
    },
    buttons: {
        type: Array,
    },
    images: {
        type: Array,
    },
});
const themeColor = inject('tierTheme', 'light');
const direction = inject('direction');
const CSSClasses = computed(() => [`theme-${themeColor}`, `size-${props.size}`, `direction-${direction.value}`]);

const addedButtonProps = {
    theme: themeColor,
};

const sizeFullButtonProps = {
    theme: 'dark',
};
</script>

<style lang="scss" scoped>
$nav-button-margin: 1.3rem;
.gallery-nav-button {
    border: 1px solid color(blue, dark, 0.7);
    border-radius: 100rem;
    width: 4.8rem;
    height: 4.8rem;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    .arrow-icon {
        color: color(blue, dark);
        width: 2.1rem;
    }
}
.gallery-figure {
    position: relative;
    &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 40%;
        background: linear-gradient(to top, color(noir, default, 0.8), transparent);
    }
    .image {
        height: 100%;
    }
}
.caption {
    position: absolute;
    bottom: 1.6rem;
    right: 1.6rem;
    max-width: 36rem;
    color: white;
    text-shadow: 1px 1px 0 black;
    text-align: right;
}
.gallery-info {
    .title {
        margin-bottom: 1.6rem;
    }
    .buttons {
        margin-top: 2.6rem;
    }
}
.gallery-buttons {
    display: flex;
    gap: 1.6rem;
}
.gallery-navigation {
    min-width: 12.5rem;
    display: flex;
    gap: 0.8rem;
    justify-content: flex-end;
}
.gallery-block.direction-rtl {
    direction: rtl;
    .caption {
        text-align: left;
        left: 1.6rem;
        right: unset;
    }
    &.size-full {
        .gallery-nav-button {
            transform: translateY(50%);
        }
        .gallery-nav-button-prev {
            right: $nav-button-margin;
            left: unset;
        }
        .gallery-nav-button-next {
            left: $nav-button-margin;
            right: unset;
        }
        .gallery-info {
            padding: 0 var(--page-margin) 0 6.5rem;
            left: unset;
            @include media-query(tablet-mw) {
                padding: 0;
                right: 8.4rem;
            }
        }
    }
}
.gallery-block.theme-dark {
    .gallery-info {
        color: color(blanc);
    }
    .gallery-nav-button {
        border: 1px solid color(blanc, default, 0.7);
        .arrow-icon {
            color: color(blanc);
        }
    }
}
.gallery-block.size-25,
.gallery-block.size-33 {
    .gallery-navigation {
        margin-top: 2.4rem;
    }

    .caption {
        max-width: 21rem;
    }
    .gallery-info {
        margin-top: 1.6rem;
    }
}
.gallery-block.size-25,
.gallery-block.size-33,
.gallery-block.size-50 {
    .gallery-figure {
        aspect-ratio: 1.67/1;
    }
    .gallery-buttons {
        margin-top: 2.6rem;
    }
}

.gallery-block.size-50 {
    .gallery-info {
        max-width: 40rem;
    }
}
.gallery-block.size-50,
.gallery-block.size-100 {
    .gallery-navigation {
        text-align: right;
    }
    .gallery-lower {
        display: flex;
        // flex-direction: row-reverse;
        justify-content: space-between;
        margin-top: 2.4rem;
    }
}
.gallery-block.size-100 {
    .gallery-figure {
        aspect-ratio: 2/1;
    }
    .gallery-buttons {
        margin-top: 1.6rem;
    }
    .gallery-pagination {
        position: static;
    }
    .gallery-info {
        max-width: 60rem;
    }
}
.gallery-block.size-full {
    position: relative;
    .gallery-buttons {
        margin-top: 3rem;
        flex-direction: column;
        @include media-query(phone-mw) {
            flex-direction: row;
        }
    }
    .car-button {
        width: fit-content;
        @include media-query(phone-mw) {
            width: auto;
        }
    }
    .gallery-figure {
        width: 100%;
        max-height: var(--image-max-height);
        aspect-ratio: 390/844;

        @include media-query(tablet-mw) {
            aspect-ratio: 2.25/1;
        }
    }
    .image {
        width: 100%;
    }
    .gallery-info {
        position: absolute;
        left: 0;
        padding: 0 6.5rem 0 var(--page-margin);
        bottom: 3.6rem;
        color: color(blanc);
        max-width: 65rem;
        @include media-query(phone-mw) {
            padding: 0 var(--page-margin);
        }
        @include media-query(tablet-mw) {
            padding: 0;
            left: 8.4rem;
        }
    }
    .gallery-pagination {
        display: none;
    }
    .gallery-nav-button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border: 1px solid color(blanc, default, 0.7);
        .arrow-icon {
            color: color(blanc);
        }
    }
    .gallery-nav-button-prev {
        left: $nav-button-margin;
        display: none;
        @include media-query(phone-mw) {
            display: flex;
        }
    }
    .gallery-nav-button-next {
        right: $nav-button-margin;
    }
}
</style>

<style lang="scss">
// We have to put styles that override the default vue3-carousel styles in an unscoped tag
.gallery-block {
    .carousel {
        position: relative;
    }
    .gallery-pagination {
        position: absolute;
        bottom: 2rem;
        left: 1.6rem;
    }
}
.gallery-pagination {
    .carousel__pagination-button {
        padding: 0.2rem;
        &::after {
            width: 0.6rem;
            height: 0.6rem;
            border-radius: 1rem;
            background-color: color(blanc, default, 0.25);
        }
    }
    .carousel__pagination-button--active::after {
        background-color: color(blanc);
    }
}
.gallery-block.size-100 {
    .gallery-pagination {
        margin-top: 1.6rem;
        .carousel__pagination-button {
            &::after {
                background-color: color(noir, default, 0.25);
            }
        }
        .carousel__pagination-button--active::after {
            background-color: color(noir);
        }
    }
    &.theme-dark {
        .gallery-pagination {
            .carousel__pagination-button {
                &::after {
                    background-color: color(blanc, default, 0.25);
                }
            }
            .carousel__pagination-button--active::after {
                background-color: color(blanc);
            }
        }
    }
}
.gallery-block.direction-rtl {
    .gallery-pagination {
        left: unset;
        right: 1.6rem;
    }
}
</style>
